@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300&family=Ubuntu:wght@300&display=swap");

body {
  margin: 0;
  font-family: "Outfit", "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {
  #sbe3 {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    background-color: #000000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #ffffff;
  }
}

@media screen and (min-width: 768px) {
  #sbe3 {
    display: none;
  }
}